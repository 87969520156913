<div class="card-body sso-modal dark-mode">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Add Provider</h4>
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="mdc.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="row mb-4">
			<div class="col p-0 px-2">
				<div class="form-group m-form__group">
					<div class="input-group">
						<div class="input-group-prepend"><span class="input-group-text mt-0"><i class="fa fa-search"></i></span></div>
						<input [(ngModel)]="keyword" (keyup)="search_records()" class="form-control" type="text" placeholder="Search providers">
					</div>
				</div>
			</div>
		</div>
		<div class="row content">
			<div *ngFor="let p of filtered" class="col-xl-3 col-md-4 mb-4">
				<div class="cardz card-with-bordern b-r-5 mb-1" style="background-color: white; padding: 5px">
					<img class="img-fluid p-1" [src]="p.logo" alt="img">
				</div>
				<p class="text-white text-center mb-1" [innerHTML]="p.provider_name || 'n/a'"></p>
				<button type="button" class="btn btn-warning btn-sm d-block w-100" (click)="submit(p)">Add Provider</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="form row justify-content-md-center">
			<div class="col-md-8 mb-0">
				<!-- <button type="button" class="btn btn-warning btn-lg d-block w-100">Add Branch</button> -->
			</div>
		</div>
	</div>
</div>