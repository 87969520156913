import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { UrlService } from './url.service';

@Injectable({
  	providedIn: 'root'
})
export class ApiService implements OnInit {
	public showLoader: boolean = false;
	public baseUrl: string = this.url.get_host('sso_api');

	constructor(public http: HttpClient, private url: UrlService) {

	}

	ngOnInit(): void { }

	// auth: sign in
	signin(data: any) {
		return this.http.post<any>(`${this.baseUrl}/sso-login`, data).pipe(map(resp => {
			return resp;
		}));
	}

	// auth: sign out
	signout() {
		return this.http.post<any>(`${this.baseUrl}/logout`, null).pipe(map(resp => {
			return resp;
		}));
	}

	// auth: resend pin
	resend_pin(data: any) {
		return this.http.post<any>(`${this.baseUrl}/resend-pin`, data).pipe(map(resp => {
			return resp;
		}));
	}

	// user: get profile
	profile() {
		return this.http.get<any>(`${this.baseUrl}/sso-user-profile`).pipe(map(resp => {
			return resp;
		}));
	}

	// get providers
	get_providers(param: string = '?category=all') {
		return this.http.get(`${this.baseUrl}/providers${param ?? ''}`).pipe(map(resp => {
			return resp;
		}));
	}

	update_assigned_provider(id: number, data: any) {
		return this.http.put(`${this.baseUrl}/sso-provider-launch/${id}`, data).pipe(map(resp => {
			return resp;
		}));
	}
	
	// auth: verify-pin
	verify_pin(data: any) {
		return this.http.post(`${this.baseUrl}/sso-verify-pin`, data).pipe(map(resp => {
			return resp;
		}));
	}

	// sso-dashboard: my-providers
	personal_providers() {
		return this.http.get<any>(`${this.baseUrl}/sso-my-providers`).pipe(map(resp => {
			return resp;
		}));
	}

	// sso-dashboard: branch-providers
	branch_providers() {
		return this.http.get(`${this.baseUrl}/sso-my-branch-providers`).pipe(map(resp => {
			return resp;
		}));
	}

	get_branch_provider(id: number) {
		return this.http.get(`${this.baseUrl}/sso-provider-objects/${id}?object-source=branch`).pipe(map(resp => {
			return resp;
		}));
	}

	get_user_provider(id: number) {
		return this.http.get(`${this.baseUrl}/sso-provider-objects/${id}?object-source=user`).pipe(map(resp => {
			return resp;
		}));
	}

	get_provider_launch(type: string, id: number) {
		return this.http.get(`${this.baseUrl}/sso-provider-objects/${id}?object-source=${type}`).pipe(map(resp => {
			return resp;
		}));
	}

	// sso-dashboard: save-user-credentials
	save_credentials(data: any) {
		return this.http.put(`${this.baseUrl}/sso-my-provider`, data).pipe(map(resp => {
			return resp;
		}));
	}
	
	// sso-my-providers: add provier
	assign_provider(data: any) {
		return this.http.post(`${this.baseUrl}/sso-provider-launch`, data).pipe(map(resp => {
			return resp;
		}));
	}

	// request_provider
	request_provider(data: any) {
		return this.http.post(`${this.baseUrl}/sso-provider-request`, data).pipe(map(resp => {
			return resp;
		}));
	}
}