<div class="card-body sso-modal dark-mode">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Provider Credentials</h4>
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="row justify-content-md-center mb-3">
			<div class=" col-md-5 cardz b-r-5 mb-3">
				<img class="img-fluid" [src]="image" alt="img">
			</div>
			<p class="text-white text-center f-s-italic">
				Please provide authentication credentials to connect this provider to your Single Sign-on account.
			</p>
		</div>
		<form [formGroup]="form" class="form theme-form custom-datepicker custom-datepicker-dropdown">
			<div class="form-group">
				<label class="required" for="input1">Username:</label>
				<input class="form-control" id="input1" type="text" autocomplete="username"  placeholder="Enter username" formControlName="username"/>
			</div>
			<div class="form-group">
				<label class="required" for="input2">Password:</label>
				<input class="form-control" id="input2" [type]="show ? 'text' : 'password'" placeholder="********" autocomplete="new-password"  formControlName="password"/>
				<div class="show-hide" (click)="toggle()" *ngIf="!show"><span class="show"></span></div>
				<div class="show-hide" (click)="toggle()" *ngIf="show"><span class="Hide"></span></div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<div class="form row justify-content-md-center">
			<div class="col-md-12 mb-0">
				<button type="button" class="btn btn-warning btn-lg d-block w-100" (click)="submit()">Save Credentials</button>
			</div>
		</div>
	</div>
</div>