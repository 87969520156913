import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { first } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api.service';
import { DataService } from '../../shared/services/data.service';

import { StatusCellRenderer } from 'src/app/shared/actions/status-cell-renderer';
import { ProviderLogoCellRenderer } from 'src/app/shared/actions/provider-logo-cell-renderer';
import { ProviderDetailCellRenderer } from 'src/app/shared/actions/provider-detail-cell-renderer';
import { MoreActionCellRenderer } from 'src/app/shared/actions/more-action-cell-renderer';
import { UserPinPromptComponent } from '../user-pin-prompt/user-pin-prompt.component';
import { ProviderCredentialComponent } from '../provider-credential/provider-credential.component';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { AuthService } from 'src/app/auth/auth.service';
import { AddProviderComponent } from '../add-provider/add-provider.component';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-my-providers',
	templateUrl: './my-providers.component.html',
	styleUrls: ['./my-providers.component.scss']
})
export class MyProvidersComponent implements OnInit {
	public user: any;
	public user_id: number;
	public keyword: string = '';
	public records: any[] = [];
	public filtered: any[] = [];
	public columns: any[] = [
		{ 
			headerName: 'Logo', width: 135,  flex: 0, 
			sortable: false, filter: false, resizable: false,
			cellRenderer: ProviderLogoCellRenderer
		},
		{ headerName: 'Description', field: 'position', flex: 1, cellRenderer: ProviderDetailCellRenderer, },
		{ 
			headerName: 'Status', 
			colId: 'action', width: 130, flex: 0, 
			sortable: false, filter: false, resizable: false,
			cellRenderer: StatusCellRenderer,
			cellRendererParams: { onClick: this.onAction.bind(this), text: 'Status' }
		},
		{ 
			headerName: 'Action', 
			colId: 'action', width: 130, flex: 0, 
			sortable: false, filter: false, resizable: false,
			cellStyle: { 'justify-content': "flex-end" },
			cellRenderer: MoreActionCellRenderer,
			cellRendererParams: { onClick: this.onAction.bind(this), mode: 'user' }
		},
	]
	
	private gridApi!: GridApi;
	public defaultColDef: ColDef = {
		flex: 1,
		sortable: false,
		resizable: false,
		filter: false,
	};

	constructor(
		private route: ActivatedRoute,
		private auth: AuthService, private api: ApiService, private dts: DataService, 
		public mdc: NgbActiveModal, private modal: NgbModal, private pop: DialogService) { 
			this.user_id = this.auth.get_user_id();
			this.api.profile().pipe(first()).subscribe((p) => {
				this.user = p.data;
			});	
		}

	ngOnInit(): void { }
	onGridReady = (params: GridReadyEvent) => {
		this.gridApi = params.api;
		this.refresh_records();
	}

	// fetch records
	refresh_records() {
		this.api.personal_providers().pipe(first()).subscribe({
			next: (p: any) => {
				this.records = p.data || [];
				this.filtered = p.data || [];
			},
			error: (x) => {
				this.records = [];
				this.filtered = [];
			}
		});
	}


	// search handler
	search_records(): void {
		this.filtered = this.dts.search(this.keyword, {
			original: this.records,
			filtered: this.filtered,
			columns: ['provider_name']
		}) || this.filtered || [];
	}

	// add provider modal
	add_provider_modal() {
		const modalRef = this.modal.open(AddProviderComponent, {
			backdrop: 'static', keyboard: false,
			centered: true, size: 'lg'
		});

		modalRef.componentInstance.type = 'user';
		modalRef.componentInstance.user_id = this.user_id;
		modalRef.result.then((p) => this.refresh_records()).catch((e) => {});
	}

	// save credential
	async update_user_credential(id: number, data: any) {
		this.api.update_assigned_provider(id, data).pipe(first()).subscribe({
			next: (u) => { 
				this.pop.toast(null, 'The Provider\'s credential has been updated successfully.').then(() => {
					this.refresh_records();
				});
			},
			error: (x) => {								
				this.pop.toast(null, 
					'Sorry, we are unable to update your credential at the moment. Please try again later.').then(() => {
					this.refresh_records();
				})
			}
		});
	}

	// actions handler
	onAction(result: any) {
		let action = result.event.target.dataset.crud;
		if (action === 'status') {
			this.gridApi.redrawRows();
		}

		// edit || drop
		if (['edit', 'drop'].includes(action)) {
			const modalRef = this.modal.open(UserPinPromptComponent, {
				backdrop: 'static', keyboard: false,
				centered: true, size: 'md'
			});

			modalRef.result.then((data) => {
				if (data === true) {
					// update credentials
					if (action === 'edit') {
						this.api.get_user_provider(result.node.data.provider_id).pipe(first()).subscribe({
							next: (u: any) => {
								const credRef = this.modal.open(ProviderCredentialComponent, {
									backdrop: 'static', keyboard: false,
									centered: true, size: 'md'
								});
	
								// pass arguments to modal instance
								credRef.componentInstance.image = u.data.logo;
								credRef.componentInstance.username = u.data.username;
								credRef.componentInstance.password = u.data.password;
								credRef.result.then((resp) => {
									// persist credential to server
									this.update_user_credential(result.node.data.provider_launch_id, {
										username: resp.username,
										password: resp.password
									});
								}).catch((e) => {});
							},
							error: (x) => {
								this.pop.toast(null, 
									'Sorry, we are unable to retreieve provider details. Please try again'
								);
							}
						});
					}

					// delete credentials
					if (action === 'drop') {
						const modalRef = this.modal.open(ConfirmDeleteComponent, {
							backdrop: 'static', keyboard: false,
							centered: true, size: 'md'
						});
			
						modalRef.componentInstance.name = result.node.data.provider_name;
						modalRef.result.then((confirmed) => {
							if (confirmed === true) {
								this.update_user_credential(result.node.data.provider_launch_id, {
									username: "", 
									password: ""
								});
							}
						}).catch((e) => {});
					}
				}
			});
		}
	}
}
