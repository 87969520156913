import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { NgOtpInputModule } from  'ng-otp-input';
import { AgGridModule } from 'ag-grid-angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CountToModule } from 'angular-count-to';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';


// Header Elements Components
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
import { DateSuffixPipe } from './directives/date-suffix.directive';
import { HostnamePipe } from './directives/hostname.directive';
// Services
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SpinnerInterceptor } from './interceptors/spinner.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { HttpErrorInterceptor } from './interceptors/http.error.interceptor';
import { DialogComponent } from './components/dialog/dialog.component';
import { AuthUserGuard } from '../auth/auth-user.guard';
import { UrlService } from './services/url.service';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		SidebarComponent,
		ContentComponent,
		BreadcrumbComponent,
		FeatherIconsComponent,
		FullComponent,
		ShowOptionsDirective,
		DisableKeyPressDirective,
		OnlyAlphabetsDirective,
		OnlyNumbersDirective,
		DateSuffixPipe,
		HostnamePipe,
		LoaderComponent,
		TapToTopComponent,
		MyAccountComponent,
		DialogComponent,
  		ConfirmDeleteComponent,
    	SpinnerOverlayComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
    	MatSelectModule,
		NgbModule,
		DragulaModule.forRoot(),
		TranslateModule
	],
	providers: [
		NavService,
		LayoutService,
		UrlService,


		AuthUserGuard,
		//AuthGuestGuard,
		//SubscriptionGuard,
		
		{ provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
	],
	exports: [
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		LoaderComponent,
		BreadcrumbComponent,
		FeatherIconsComponent,
		TapToTopComponent,
		NgOtpInputModule,
		AgGridModule,
		CountToModule,
		NgSelectModule,
		NgxDatatableModule,
		NgxIntlTelInputModule,
		DisableKeyPressDirective,
		OnlyAlphabetsDirective,
		OnlyNumbersDirective,
		DateSuffixPipe,
		HostnamePipe,
		ConfirmDeleteComponent,
		DialogComponent
	],
	entryComponents: [DialogComponent]
})
export class SharedModule { }
