import { Component } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'more-action-renderer',
	template: `
		<div ngbDropdown class="d-inline-block">
			<button type="button" class="btn btn-outline-warning px-3 dropdown-toggle" data-bs-toggle="dropdown" 
				data-flip="false" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" ngbDropdownToggle>
				<i class="fa fa-ellipsis-v fw-semi-bold text-white"></i>
			</button>
			<div class="dropdown-menu" ngbDropdownMenu>
				<a disabled data-crud="edit" class="dropdown-item" ngbDropdownItem (click)="onClick($event)">Edit credentials</a>
				<a data-crud="drop" class="dropdown-item" ngbDropdownItem (click)="onClick($event)">Delete</a>
			</div>
		</div>
	`,
	
})
export class MoreActionCellRenderer implements ICellRendererAngularComp {
	private params: any;
	public label: string = 'action'

	constructor(config: NgbDropdownConfig) {
		config.placement = 'top-left';
		config.autoClose = false;
	}
  
	agInit(params: any): void {
		this.params = params;
		this.label = params.text;
	}

	refresh(_params: ICellRendererParams<any, any>): boolean {
		return true;
	}

	onClick($event: any) {
		if (this.params.onClick instanceof Function) {
			if (this.params.column.colId.includes('action') && $event.target.dataset.crud) {
				this.params.onClick({
					event: $event,
					column: this.params.column,
					node: this.params.node
				});
			}
		}
	}
}