<div class="card-body sso-modal dark-mode">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Confirm Deletion</h4>
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss(false)"></button>
	</div>
	<div class="modal-body pt-4 pb-4">
		<div class="text-center text-white">
			<h5 class="mb-3">
				You are about to delete <span class="text-warning fw-bold"><i class="title"></i>{{name}}</span> record. This action is irreversible.
			</h5>
            <h5 class="mb-0">Do you wish to proceed?</h5>
		</div>
	</div>
	<div class="modal-footer">
		<div class="form row">
			<div class="col-md-6 mb-0">
				<button type="button" class="btn btn-outline-warning btn-lg d-block w-100" (click)="modal.close(false)">Cancel</button>
			</div>
			<div class="col-md-6 mb-0">
				<button type="button" class="btn btn-warning btn-lg d-block w-100" (click)="modal.close(true)">Confirm</button>
			</div>
		</div>
	</div>
</div>