import { Routes } from '@angular/router';
import { AuthUserGuard } from 'src/app/auth/auth-user.guard';

export const content: Routes = [
	{
		path: 'u',
		canActivate: [AuthUserGuard],
		loadChildren: () => import('../../user/user.module').then(m => m.UserModule),
	},
];
