import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'status-renderer',
	template: `
		<div class="media">
			<div class="media-body text-right icon-state switch-wide">
				<label class="switch">
					<input type="checkbox" #status data-crud="status" (change)="onClick($event)"><span class="switch-state"></span>
				</label>
			</div>
		</div>
	`,
})
export class StatusCellRenderer implements ICellRendererAngularComp, AfterViewInit {
	private params: any;
	public label: string = 'action'

	@ViewChild('status') public input: ElementRef;
  
	agInit(params: any): void {
		this.params = params;
		this.label = params.text;
	}

	ngAfterViewInit() {
		this.input.nativeElement.checked = this.params.data.is_active || this.params.data.active || true;
	}

	refresh(_params: ICellRendererParams<any, any>): boolean {
		return true;
	}

	onClick($event: any) {
		if (this.params.onClick instanceof Function) {
			if (this.params.column.colId === 'action' && $event.target.dataset.crud) {
				this.params.onClick({
					event: $event,
					column: this.params.column,
					node: this.params.node
				});
			}
		}
	}
}