import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthUserResolver } from './auth/auth-user.resolver';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { FullComponent } from './shared/components/layout/full/full.component';

import { full } from "./shared/routes/full.routes";
import { content } from './shared/routes/routes';

const routes: Routes = [
	{ path: '', redirectTo: '/auth/signin', pathMatch: 'full' },
	{
		path: '',
		component: ContentComponent,
		children: content,
		resolve: { auth: AuthUserResolver },
	},
	{
		path: '',
		component: FullComponent,
		children: full,
		resolve: { auth: AuthUserResolver },
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
			relativeLinkResolution: 'legacy'
  		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
