import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-request-provider',
  templateUrl: './request-provider.component.html',
  styleUrls: ['./request-provider.component.scss']
})
export class RequestProviderComponent implements OnInit {
	form: FormGroup;

	constructor(
		private api: ApiService, public modal: NgbActiveModal, 
		private builder: FormBuilder, private pop: DialogService) {
			this.form = this.builder.group({
				provider_name: ['', [Validators.required]],
				base_url: ['', [Validators.required]],
				provider_description: ['', ]
			});
		}

	ngOnInit(): void {}
	submit() {
		this.api.request_provider(this.form.value).pipe(first()).subscribe({
			next: (p) => {
				this.modal.close(true);
				this.pop.toast(null,
					'We have received your request, and will attend to it shortly. Thank You.', 
				);
			},
			error: (x) => {
				let message = x.error.data || 'Sorry, we are unable to handle your request at the monent. Please try again';
				if (typeof x.error.data !== 'undefined' && typeof x.error.data === 'object') {
					if (x.error.data.length > 0) {
						message = x.error.data[0].message;
					}
				}

				// show error message
				this.pop.toast(null, message);
			}
		})
	}
}
