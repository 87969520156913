import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'provider-detail-renderer',
	template: `
		<div class="pt-2">
			<div class="">
				<h6 class="title" [innerHTML]="name">NHS Spine</h6>
				<div class="content-general">
					<p class="desc mb-0" [innerHTML]="text"></p>
					<span class="seen">Last signed in: <span class="date">Never</span></span>
				</div>
			</div>
		</div>
	`,
})
export class ProviderDetailCellRenderer implements ICellRendererAngularComp {
	private params: any;
	public label: string = 'action'

	public name;
	public text;
  
	agInit(params: any): void {
		this.params = params;
		this.label = params.text;
		
		this.name = params.data.provider_name;
		this.text = params.data.provider_name;
	}

	refresh(_params: ICellRendererParams<any, any>): boolean {
		return true;
	}

	onClick($event: any) {
		if (this.params.onClick instanceof Function) {
			if (this.params.column.colId === 'action' && $event.target.dataset.crud) {
				this.params.onClick({
					event: $event,
					column: this.params.column,
					node: this.params.node
				});
			}
		}
	}
}