import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { catchError } from 'rxjs/operators';
import { UrlService } from '../services/url.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private url: UrlService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if account is logged in and request is to the api url
		const auth_token = this.auth.get_auth_token();
		const is_api_url = request.url.startsWith(this.url.get_host('sso_api'));

		// // only if is_api_url && auth_token is not null
		if (auth_token && auth_token.id_token && is_api_url) {
			if (request.url.includes('password')) {
				request = request.clone({
					setHeaders: {Authorization: `Bearer ${auth_token.access_token}`}
				});
			} else {
				request = request.clone({
					setHeaders: {Authorization: `Bearer ${auth_token.id_token}`}
				});
			}
		}

		return next.handle(request).pipe(
			catchError((err) => {
			  	if (err instanceof HttpErrorResponse) {
				  	if (err.status === 401) {
				  		// redirect user to the logout page
			   		}
				}
				return throwError(err);
		  	})
		);
    }
}