import { Routes } from '@angular/router';
import { AuthUserGuard } from 'src/app/auth/auth-user.guard';
import { AuthGuestGuard } from 'src/app/auth/auth-guest.guard';

export const full: Routes = [
	{
		path: 'auth',
		canActivate: [AuthGuestGuard],
		loadChildren: () => import('../../auth/auth.module').then(m => m.AuthModule),
	}
];
