<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
	<div class="header-wrapper row m-0">
		<div class="header-logo-wrapper">
			<div class="logo-wrapper">
				<a routerLink='/'>
					<img class="img-fluid for-light" src="assets/images/logo/sso-logo@white.png" alt="sso-logo">
					<img class="img-fluid for-dark" src="assets/images/logo/sso-logo@white.png" alt="sso-logo">
				</a>
			</div>
			<div class="toggle-sidebar" (click)="sidebarToggle()">
				<app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
			</div>
		</div>
		<div class="left-header col horizontal-wrapper pl-0"></div>
		<div class="nav-right col-8 pull-right right-header p-0">
			<ul class="nav-menus">
				<li class="maximize">
					<a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
						<app-feather-icons [icon]="'maximize'"></app-feather-icons>
					</a>
				</li>
				<li class="profile-nav onhover-dropdown p-0">
					<app-my-account></app-my-account>
				</li>
			</ul>
		</div>
	</div>
</div>
<!-- Page Header Ends -->