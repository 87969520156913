<div class="card-body sso-modal feedback dark-mode">
	<div class="modal-header">
		<h4 class="modal-title text-center" id="modal-basic-title">{{ title || 'System Feeback' }}</h4>
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.close(true)"></button>
	</div>
	<div class="modal-body">
		<div class="text-center">
			<h6 class="text-white mb-0" [innerHTML]="message || 'no message'"></h6>
		</div>
	</div>
	<div class="modal-footer">
		<div class="form row">
			<div class="col-md-6 mb-0">
				<!-- <button type="button" class="btn btn-outline-danger btn-lg w-100" (click)="modal.dismiss(false)">Cancel</button> -->
			</div>
			<div class="col-md-6 mb-0">
				<button type="button" class="btn btn-outline-warning btn-lg w-100" (click)="modal.close(true)">OK</button>
			</div>
		</div>
	</div>
</div>