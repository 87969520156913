import { Injectable } from '@angular/core';
import Fuse from 'fuse.js'

@Injectable({
  	providedIn: 'root'
})
export class DataService {
  	constructor() { }

	// search records
	search(keyword: string, options: any) {
		if (typeof keyword !== 'undefined' && typeof options !== 'undefined') {
			if (typeof options.original !== 'undefined' && typeof options.columns !== 'undefined') {
				let fuse = new Fuse(options.original || [], {
					minMatchCharLength: 3,
					keys: options.columns || []
				});

				// perform search
				return keyword.length < 3 ? 
					options.original : 
					fuse.search(keyword).map((p) => p.item);
			}
		}
	}

	// order records
	order(mode: string, options: any) {
		if (typeof mode !== 'undefined' && typeof options !== 'undefined') {
			const original = [...options.original];

			if (['category', 'recent', 'most-used'].includes(mode)) {
				// order: recently added
				if (mode == 'recent') {
					return original.sort((a: any, b: any) => {
						const date1 = Date.parse(a.created_at);
						const date2 = Date.parse(b.created_at);
						return date1 < date2 ? 1 : -1;
					});
				}

				// order: category
				if (mode == 'category') {
					return original.sort(
						(a: any, b: any) => b.category.localeCompare(a.category)
					);
				}

				// order: most-used
				if (mode == 'most-used') {
					return original.sort((a: any, b: any) => {
						const numb1 = parseInt(a.launch_frequency);
						const numb2 = parseInt(b.launch_frequency);
						return numb1 < numb2 ? 1 : -1;
					});
				}
			}

			return original;
		}
	}
}