import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
	@Input() title: string;
  	@Input() message: string;
 
	constructor(public modal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
		//console.log("DialogComponent construct");
	}
   
	ngOnInit() {
		//console.log("DialogComponent init");
	}
}
