import { Injectable, OnInit } from '@angular/core';
import { environment as conf } from '../../../environments/environment';

@Injectable({
  	providedIn: 'root'
})
export class UrlService {
	get_host(site?: string) {
		const host_name: string = window.location.hostname.toLowerCase();
		const base_host: string = `${window.location.protocol}//${window.location.host}`;
		const is_develop: boolean = host_name.includes('local') || host_name.includes('dev');
		const is_staging: boolean = host_name.includes('staging');

		// environment: development
		if (is_develop && !is_staging) {
			if (site.includes('sso_api')) {
				return this.host(site, 'dev');
			}
			return this.host(site, host_name.includes('local') ? 'local' : 'dev');
		}

		// environment: staging
		else if (is_staging && !is_develop) {
			return this.host(site, 'staging');
		}

		// environment: production
		else if (!is_develop && !is_staging) {
			return this.host(site, 'production');
		}

		return base_host;
	}

	// extract url from env file
	private host(site: string, env: string) {
		if (site != null && env != null) {			
			return conf[`${site}_${env}_url`] ?? null;
		}
		return null;
	}
}