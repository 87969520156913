import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService } from '../../shared/services/dialog.service';

@Injectable({
  	providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  	constructor(private pop: DialogService) {

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError((e: HttpErrorResponse) => {
			const error = (e && e.error && e.error.message) || e.statusText;

            if ([401, 403].includes(e.status)) {
				if (error.toLowerCase().includes('not authorized to access this resource')) {
					this.pop.toast(null,
						'Sorry, you are not authorized to make this request. Please try again later or contact us.',
					);
				}
				else {
					// auto logout if 401 or 403 response returned from api
                	// this.accountService.logout();
					console.log('forbidden: force logout', e);
				}
            }

			if (error.toLowerCase().includes('invalid credential')) {
				const message = 'Sorry, these credentials do not match any of our records. ' +
					'Please ensure they are correct and try logging in again.' +
					'<br><br>Thank You.';

				this.pop.toast(null, message);
			}

            return throwError(e);
        }));
	}
}
