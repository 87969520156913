import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { first } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
	selector: 'app-user-pin-prompt',
	templateUrl: './user-pin-prompt.component.html',
	styleUrls: ['./user-pin-prompt.component.scss']
})
export class UserPinPromptComponent implements OnInit {
	form: FormGroup;
	public isPinOk: boolean = false;
	public userPin: string = '';
	public config = {
		length: 6,
		disableAutoFocus: true,
		allowNumbersOnly: false,
		isPasswordInput: false,
		letterCase: 'upper',
		inputStyles: {
			'color': 'white',
			'font-size': '30px',
			'border': '2px solid #CDCDD6',
			'background': 'rgba(255, 255, 255, 0.25)'
		}
	};

	constructor(
		private auth: AuthService, public modal: NgbActiveModal, 
		private builder: FormBuilder, private pop: DialogService) {
			this.form = this.builder.group({

			});
	}

	ngOnInit(): void {}

	// on otp change
	public async onOtpChange(code: string) {
		this.isPinOk = code.length === 6;
		this.userPin = code;
	}

	submit() {
		this.auth.verify_user_pin({pin: this.userPin}).pipe(first()).subscribe({
			next: (p) => {
				localStorage.setItem('auth', JSON.stringify(p['data']));
				this.modal.close(true);
			},
			error: (x) => {
				let message = x.error.data || 'Sorry, we are unable to verify your Identity at the monent. Please try again';
				if (typeof x.error.data !== 'undefined' && typeof x.error.data === 'object') {
					if (x.error.data.length > 0) {
						message = x.error.data[0].message;
					}
				}

				// show error message
				this.pop.toast(message, 'Unable to verify PIN');
			}
		});
	}
}
