<div class="card-body sso-modal dark-mode">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Request Provider</h4>
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="form" class="form theme-form">
			<div class="form-group">
				<label class="required" for="input1">Provider Name:</label>
				<input id="input1" type="text" class="form-control" placeholder="Enter provider name" formControlName="provider_name"/>
			</div>
			<div class="form-group">
				<label class="required" for="input2">Provider URL:</label>
				<input id="input2" type="text" class="form-control" placeholder="Enter provider URL" formControlName="base_url"/>
			</div>
			<div class="form-group">
				<label for="input3">Provider Description:</label>
				<textarea id="input3" class="form-control" rows="8" placeholder="Enter description" formControlName="provider_description"></textarea>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<div class="form row justify-content-md-center">
			<div class="col-md-12 mb-0">
				<button type="button" class="btn btn-warning btn-lg d-block w-100" (click)="submit()">Submit Request</button>
			</div>
		</div>
	</div>
</div>