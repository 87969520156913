import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  	providedIn: 'root'
})
export class DialogService {
	constructor(private modal: NgbModal, private toastr: ToastrService) {

	}

	// uses ng-boostrap modal
	public show(title: string, message: string, callback?: any) {
		const modalRef = this.modal.open(DialogComponent, {
			backdrop: 'static', keyboard: false,
			centered: true, size: 'md'
		});

		modalRef.componentInstance.title = title;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.changeRef.markForCheck();

		return modalRef.result.then(() => callback()).catch((e) => {});
	}

	// uses ngx-toastr
	public async toast(title: string, message: string) {
		this.toastr.info(message || 'enter message here', title || 'System Feedback', {
			timeOut: 10000, enableHtml: true,
			closeButton: true, tapToDismiss: true,
		});
	}
}
