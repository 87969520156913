import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPinPromptComponent } from 'src/app/user/user-pin-prompt/user-pin-prompt.component';
import { RequestProviderComponent } from 'src/app/user/request-provider/request-provider.component';
import { MyProvidersComponent } from 'src/app/user/my-providers/my-providers.component';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
	public user: any;
	public role_id: number = 0;
	public home_id: string = '';

  	constructor(public modal: NgbModal, private route: ActivatedRoute, private router: Router) { 
		this.route.data.subscribe((response: any) => {
			this.user = response['auth']['data'];
		});
	}

	ngOnInit() { }

	my_providers() {
		this.verify_user((data: boolean) => {
			if (data === true) {
				this.modal.open(MyProvidersComponent, {
					backdrop: 'static', keyboard: false,
					centered: true, size: 'xl'
				});
				
				// this.router.navigate(['/u/dashboard']).then((x) => {
				// 	location.reload();
				// });
			}
		});
	}

	make_request() {
		this.verify_user((data: boolean) => {
			if (data === true) {
				this.modal.open(RequestProviderComponent, {
					backdrop: 'static', keyboard: false,
					centered: true, size: 'md'
				});
			}
		});
	}

	// verify user
	private verify_user(callback: Function) {
		const modalRef = this.modal.open(UserPinPromptComponent, {
			backdrop: 'static', keyboard: false,
			centered: true, size: 'md'
		});

		modalRef.result.then((data) => { return callback(data); }).catch((e) => {});
	}

	sign_out() {
		localStorage.clear();
		this.router.navigate(['/auth/signin']);
	}
}
