<div class="card-body sso-modal dark-mode">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Enter User PIN</h4>
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="form" class="form theme-form">
			<p class="text-white text-center f-16 fw-light mt-3 mb-4">
				Please enter your PIN below to verify your identity, in order to proceed with your request. 
			</p>
			<div class="d-flex justify-content-center mt-3 mb-3">
				<ng-otp-input (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<div class="form row justify-content-md-center">
			<div class="col-md-12 mb-2">
				<button [disabled]="!isPinOk" type="button" class="btn btn-warning btn-lg w-100 mb-3" (click)="submit()">Verify Identity</button>
				<button type="button" class="btn btn-outline-warning btn-lg w-100" (click)="modal.dismiss()">Cancel</button>
			</div>
		</div>
	</div>
</div>