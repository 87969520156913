import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerOverlayService } from '../services/spinner-overlay.service';
import { UrlService } from '../services/url.service';

@Injectable({
  	providedIn: 'root'
})
export class SpinnerInterceptor implements HttpInterceptor {
	constructor(private readonly sos: SpinnerOverlayService, private url: UrlService) {

	}
  
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const is_api_url = request.url.startsWith(this.url.get_host('sso_api'));

		// only when calling api urls
		if (is_api_url || (request.url.includes('http') && request.responseType === 'json')) {
			const sub: Subscription = this.sos.spinner$.subscribe();
			return next.handle(request).pipe(
				finalize(() => sub.unsubscribe())
			);
		}

		return next.handle(request);
	}
}