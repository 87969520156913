import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataService } from 'src/app/shared/services/data.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ProviderCredentialComponent } from '../provider-credential/provider-credential.component';

@Component({
  selector: 'app-add-provider',
  templateUrl: './add-provider.component.html',
  styleUrls: ['./add-provider.component.scss']
})
export class AddProviderComponent implements OnInit {
	public form: FormGroup;
	@Input() public type: string;
	@Input() public user_id: number = 0;
	@Input() public branch_id: number = 0;
	@Input() public exclude: number[];

	public keyword: string = '';
	public records: any[] = [];
	public filtered: any[] = [];

	constructor(
		private api: ApiService, private pop: DialogService, private modal: NgbModal, 
		public mdc: NgbActiveModal, private builder: FormBuilder, private dts: DataService) {
			this.form = this.builder.group({
				provider_id: ['', [Validators.required]],
				user_id: ['', [Validators.required]],
				branch_id: [0, [Validators.required]],
				username: ['', [Validators.required]],
				password: ['', [Validators.required]],
			});
		}

	ngOnInit(): void {
		this.form.patchValue({ user_id: this.user_id, branch_id: this.branch_id });
		this.api.get_providers(`?category=user`).pipe(first()).subscribe({
			next: (p) => {
				this.records = p['data'];
				this.filtered = p['data'];
			},
			error: (x) => {
				this.pop.toast(null, 
					'Sorry, we are unable to get a list of providers. Please try again.'
				);
			}
		})
	}

	// search handler
	public search_records() {
		this.filtered = this.dts.search(this.keyword, {
			original: this.records,
			filtered: this.filtered,
			columns: ['provider_name', 'provider_desc', 'category']
		}) || this.filtered || [];
	}

	// handle submit event
	public submit(obj: any) {
		this.form.patchValue({ provider_id: obj.id });
		if (obj.category === 'user') {
			const modalRef = this.modal.open(ProviderCredentialComponent, {
				backdrop: 'static', keyboard: false,
				centered: true, size: 'md'
			});

			modalRef.componentInstance.image = obj.logo;
			modalRef.componentInstance.username = null;
			modalRef.componentInstance.password = null;
			modalRef.result.then(async (data) => {
				this.form.patchValue({...data});
				
				// send data to remote server
				this.send_to_server(this.form.value);
			}).catch((e) => {});
		}
	}

	// send provider data to server
	private send_to_server(data: any ) {
		data.is_active = true;

		// send request to remote server
		this.api.assign_provider(data).pipe(first()).subscribe({
			next: (p) => {
				this.mdc.close(true);
				this.pop.toast(null, `Provider has been assigned successfully.`);	
			},
			error: (x) => {
				if (typeof x.error.data !== 'undefined') {
					if (x.error.data.includes('unique') || x.error.data.toLowerCase().includes('duplicate')) {
						this.pop.toast(null, `Sorry, this provider has already been asigned to you.`);
						this.mdc.close(true);
					} 
				} else {
					this.mdc.close(false);
					this.pop.toast(null,
						`Sorry, we are unable to assign provider to you. Please try again.`, 
					);
				}
			}
		})
	}
}
