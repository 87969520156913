<div class="col-xl-12 col-md-12 box-col-12">
	<div class="email-right-aside bookmark-tabcontent">
		<div class="card email-body radius-left mb-0">
			<div class="pl-0">
				<div class="tab-content">
					<div class="tab-pane fade active show">
						<div class="card sso-card dark-mode mb-0">
							<div class="card-header d-flex" style="padding-left: 10px !important; padding-right: 10px !important;">
								<div class="d-flex">
									<div class="form-group m-form__group">
										<div class="input-group">
											<div class="input-group-prepend"><span class="input-group-text mt-0"><i class="fa fa-search"></i></span></div>
											<input [(ngModel)]="keyword" (keyup)="search_records()" class="form-control" type="text" placeholder="Search providers">
										</div>
									</div>
									<div class="welcome" style="margin-left: 15px;">
										<button class="btn btn-lg disabled">
											Welcome <em><span class="username text-warning" [innerHTML]="user?.fullname || 'Guest'"></span>,</em> to your provider list
										</button>
									</div>
								</div>
								<ul>
									<li><button class="btn btn-warning" (click)="add_provider_modal()">Add Provider</button></li>
									<li><button class="btn btn-outline-warning mr-4" aria-label="Close" (click)="mdc.dismiss('x')">Close Window</button></li>
								</ul>
							</div>
							<div class="card-body p-0">
								<div class="modal-body p-0">
									<ag-grid-angular
										class="ag-theme-alpine-dark" style="height: 650px"
										pagination rowAnimation 
										[paginationAutoPageSize]="true"
										[enableFilter]="true" 
										[enableSorting]="true" 
										[columnDefs]="columns" (gridReady)="onGridReady($event)"
										[rowData]="filtered" 
										[rowHeight]="110"
										[overlayNoRowsTemplate]="'No providers found'"
										[defaultColDef]="defaultColDef" >
									</ag-grid-angular>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>