import { Injectable } from '@angular/core';
import {
	Router, Resolve,
	RouterStateSnapshot,
	ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from './auth.service';

@Injectable({
  	providedIn: 'root'
})
export class  AuthUserResolver implements Resolve<any> {
	constructor(public auth: AuthService, public api: ApiService) {

	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		return this.auth.is_authenticated() ? this.api.profile() : null;
	}

	private  get_auth_user() {
		return new Promise(async (resolve, reject) => {
			let auth_user = localStorage.getItem('auth_user');
			if (auth_user == null || auth_user == '') {
				await this.api.profile().toPromise().then((resp) => {
					auth_user = resp.data;
					localStorage.setItem('auth_user', JSON.stringify(resp.data));
				});
			} else {
				auth_user = JSON.parse(auth_user);
			}
			resolve(auth_user);
		});
	}
}
