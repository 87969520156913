import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
	selector: 'provider-logo-renderer',
	template: `
		<div class="details-website"><img class="img-fluid" [src]="logo" src="assets/images/nm_logo.png" alt="img">
	`,
})
export class ProviderLogoCellRenderer implements ICellRendererAngularComp {
	private params: any;
	public label: string = 'action';

	public logo;
  
	agInit(params: any): void {
		this.params = params;
		this.label = params.text;
		this.logo = params.data.logo;
	}

	refresh(_params: ICellRendererParams<any, any>): boolean {
		return true;
	}

	onClick($event: any) {
		if (this.params.onClick instanceof Function) {
			if (this.params.column.colId === 'action' && $event.target.dataset.crud) {
				this.params.onClick({
					event: $event,
					column: this.params.column,
					node: this.params.node
				});
			}
		}
	}
}