import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  	providedIn: 'root'
})
export class AuthUserGuard implements CanActivate {
	constructor(private auth: AuthService, private router: Router) {};

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const is_logged_in = this.auth.is_authenticated();
		if (is_logged_in) {
			return true;
		} else {
			this.router.navigate(['/auth/signin']);
			return false;
		}
	}
}
