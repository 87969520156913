<div class="media profile-media">
	<img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="">
	<div class="media-body">
		<span [innerHTML]="user.branch_name">John Doe</span>
		<!-- <p class="mb-0 font-roboto"><span>&nbsp;&nbsp;</span> <i class="middle fa fa-angle-down"></i></p> -->
	</div>
</div>
<ul class="profile-dropdown onhover-show-div">
	<li><a (click)="my_providers()"><app-feather-icons [icon]="'list'"></app-feather-icons><span>My Providers</span></a></li>
	<li><a (click)="make_request()"><app-feather-icons [icon]="'folder-plus'"></app-feather-icons><span>Request Provider</span></a></li>
	<li><a (click)="sign_out()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Sign Out</span></a></li>
</ul>