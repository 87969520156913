import { Injectable } from '@angular/core';

@Injectable({
  	providedIn: 'root'
})
export class LayoutService {
	public config = {
		settings: {
			layout: 'Singapore',
			layout_type: 'ltr',
			layout_version: 'dark-only',
			sidebar_type: 'default-sidebar'
		},
		color: {
			primary_color: '#e9970b', 
			secondary_color: '#232426'
		}
	}

	constructor() { 
		if (this.config.settings.layout_type == 'rtl')
		document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);
		document.documentElement.style.setProperty('--theme-default', this.config.color.primary_color);
		document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
	}
}
