import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
	selector: 'app-provider-credential',
	templateUrl: './provider-credential.component.html',
	styleUrls: ['./provider-credential.component.scss']
})
export class ProviderCredentialComponent implements OnInit {
	public form: FormGroup;
	public show: boolean = false;
	@Input() public image: string = null;
	@Input() public username: string = null;
	@Input() public password: string = null;
	
	constructor(
		public modal: NgbActiveModal, private fb: FormBuilder, private pop: DialogService) { 
			this.form = this.fb.group({
				username: ['', [Validators.required]],
				password: ['', [Validators.required]],
			});
		}

	ngOnInit(): void {
		this.form.patchValue({
			username: this.username,
			password: this.password
		});
	}
	
	public toggle = () => this.show = !this.show;
	public submit = () =>  this.modal.close(this.form.value);
}
