import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from '../shared/services/api.service';

@Injectable({
  	providedIn: 'root'
})
export class AuthService {
  	constructor(private api: ApiService) { }

	get_auth_token(): any {
		const auth_token = localStorage.getItem('auth');
		if (auth_token != null || auth_token != '') {
			return JSON.parse(auth_token);
		}
		return null;
	}

	parse_jwt(jwt: string): any {
		if (jwt !== null && typeof jwt == 'string') {
			try
			{
				return JSON.parse(atob(jwt.split('.')[1]));
			}
			catch(e) {
				return null;
			}
		}
		return null;
	}

	is_authenticated() {
		const auth = this.get_auth_token();
		if (auth == null) {
			return false;
		} else {
			try
			{
				const payload = this.parse_jwt(auth.id_token);
				const expires = new Date(payload.exp * 1000);
				const expired = (Date.now() >= payload.exp * 1000);
				return !expired;
			}
			catch(e) {
				return false;
			}
		}
	}

	get_user_id(): number {
		const payload = this.parse_jwt(this.get_auth_token()?.id_token);
		return parseInt(payload['custom:user_id']);
	}

	get_user_name(): string {
		const payload = this.parse_jwt(this.get_auth_token()?.id_token);
		return "";
	}

	get_role_id(): number {
		const payload = this.parse_jwt(this.get_auth_token()?.id_token);
		return parseInt(payload['role_id']);
	}

	signin(data: any) {
		return this.api.signin(data);
	}

	verify_user_pin(data: any) {
		return this.api.verify_pin(data).pipe(map(resp => {
			return resp;
		}));
	}

	resend_user_pin(data: any) {
		return this.api.resend_pin(data);
	}
}
